<template>
  <!-- auth-page wrapper -->
  <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
    <div class="bg-overlay"></div>
    <!-- auth-page content -->
    <div class="auth-page-content overflow-hidden pt-lg-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="card overflow-hidden">
              <div class="row g-0">
                <div class="col-lg-6">
                  <div class="p-lg-5 p-4 auth-one-bg h-100">
                    <div class="bg-overlay"></div>
                    <div class="position-relative h-100 d-flex flex-column">
                      <div class="mb-4">
                        <router-link to="/" class="d-block">
                          <img src="@/assets/images/favicon.png" alt="" height="20" />
                          <span style="color:#fff;font-size:20px;font-weight:600;position:relative;top:4px;">
                            SLiM
                          </span>
                        </router-link>
                      </div>
                      <div class="mt-auto">
                        <div class="mb-3">
                          <i class="ri-double-quotes-l display-4 text-success"></i>
                        </div>

                        <div id="qoutescarouselIndicators" class="carousel slide" data-bs-ride="carousel">
                          <div class="carousel-indicators">
                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="0"
                              class="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="1"
                              aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="2"
                              aria-label="Slide 3"></button>
                          </div>
                          <div class="carousel-inner text-center text-white-50 pb-5">
                            <div class="carousel-item active">
                              <p class="fs-15 fst-italic">
                                " Great! Clean code, clean design, easy for customization.
                                Thanks very much! "
                              </p>
                            </div>
                            <div class="carousel-item">
                              <p class="fs-15 fst-italic">
                                " The theme is really great with an amazing customer
                                support."
                              </p>
                            </div>
                            <div class="carousel-item">
                              <p class="fs-15 fst-italic">
                                " Great! Clean code, clean design, easy for customization.
                                Thanks very much! "
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- end carousel -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->

                <div class="col-lg-6">
                  <div class="p-lg-5 p-4">
                    <div>
                      <h5 class="text-primary">Bienvenu(e)!</h5>
                      <p class="text-muted">Veuillez vous connecter.</p>
                    </div>

                    <div class="mt-4">
                      <form @submit.prevent="tryToLogIn">
                        <div class="mb-3">
                          <label for="email" class="form-label">Email ou Numero de téléphone</label>
                          <input type="text" class="form-control" v-model="data.email" id="email" :class="{
                            'is-invalid':
                              (v$.data.email.$error && data.email) ||
                              (v$.data.email.$error && submited),
                          }" placeholder="Email" />
                          <div v-for="(item, index) in v$.data.email.$errors" :key="index" class="invalid-feedback">
                            <span v-if="(item.$message && data.email) ||
                              (v$.data.email.$error && submited)
                              ">
                              {{ item.$message }}
                            </span>
                          </div>
                        </div>

                        <div class="mb-3">
                          <div class="float-end">
                            <router-link to="/auth/reset-pwd-cover" class="text-muted">Mot de passe oublié?</router-link>
                          </div>
                          <label class="form-label" for="password-input">Mot de passe</label>
                          <div class="position-relative auth-pass-inputgroup mb-3">
                            <input type="password" class="form-control pe-5" v-model="data.password" :class="{
                              'is-invalid':
                                (v$.data.password.$error && data.password) ||
                                (v$.data.password.$error && submited),
                            }" id="password" placeholder="Mots de passe" name="password" />
                            <button @click="passwordToggle('password')"
                              class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button">
                              <i class="ri-eye-fill align-middle"></i>
                            </button>
                            <div v-for="(item, index) in v$.data.password.$errors" :key="index" class="invalid-feedback">
                              <span v-if="(item.$message && data.password) ||
                                (v$.data.password.$error && submited)
                                ">{{ item.$message }}</span>
                            </div>
                          </div>
                        </div>

                        <div class="form-check" style="opacity: 0;">
                          <input class="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                          <label class="form-check-label" for="auth-remember-check">Se souvenir</label>
                        </div>

                        <div class="mt-4">
                          <button class="btn btn-success w-100" type="submit" :disabled="spanView">
                            <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                            Connexion
                          </button>
                        </div>
                      </form>
                    </div>

                    <!-- <div class="mt-5 text-center">
                      <p class="mb-0">
                        vous n'avez de compte?
                        <router-link
                          to="/register"
                          class="fw-semibold text-primary text-decoration-underline"
                        >
                          Inscrivez-vous</router-link
                        >
                      </p>
                    </div> -->
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0">
                &copy; {{ new Date().getFullYear() }} SLiM. Crafted by KOF Corp
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import "vue-select/src/scss/vue-select.scss";
import Swal from "sweetalert2";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {},
  data() {
    return {
      data: {},
      // user: {},
      submited: false,
      spanView: false,
    };
  },
  validations: {
    data: {
      email: {
        required: helpers.withMessage("L'email est requis", required),
      },
      password: {
        required: helpers.withMessage("le mots de passe est requis", required),
      },
    },
  },
  computed: {
    ...mapGetters(["helloWord", 'getUserState', "getloggedUser", "selectedYear"]),
  },
  methods: {
    tryToLogIn() { this.submited = true; this.login(); },

    login() {
      this.spanView = true;
      const self = this;
      this.$store.dispatch("login", this.data).then(
        function (response) {
          self.spanView = false;
          self.$store.dispatch('changeUserStatus', { authenticated: true }),
            sessionStorage.setItem("authenticated", true),
            self.$router.push("/"),
            Swal.fire(response.data.message, "Connecté!", "success");
        },
        function (error) {
          self.spanView = false;
          Swal.fire({
            title: "Oops...",
            text: error.message,
            icon: "error",
            confirmButtonClass: "btn btn-primary w-xs mt-2",
            buttonsStyling: false,
            showCloseButton: true,
          });
        }
      )
    },

    passwordToggle(id) {
      var value = "password";
      var type = document.getElementById(id).getAttribute("type");

      if (type === value) value = "text";

      document.getElementById(id).setAttribute("type", value);
    },
  },
  mounted() {
    this.v$.data.$touch();
  },
};
</script>
